import React from 'react';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import ContactFormCmp from '../components/ContactFormCmp';

const ContactPage = () => (
  <Layout>
    <Seo title='Contact Us' />

    <div className='py-12'>
      <div className='container py-6'>
        <section className='text-gray-700 body-font'>
          <div className='container px-5 mx-auto'>
            <div className='flex flex-col w-full mb-12 text-center'>
              <h1 className='py-4 text-center font-slim text-primary'>
                Contact Langino Law
              </h1>
              <div className='flex flex-col items-center justify-center md:flex-row'>
                <div className='max-w-md mb-10 rounded-full shadow-2xl md:mb-0 md:mr-8'>
                  <img
                    alt='card img'
                    className='rounded'
                    src={`/images/adam/langino-inspection.jpg`}
                  />
                </div>
                <p className='mx-auto text-base leading-relaxed text-left lg:w-2/3'>
                  You can schedule a free consultation by calling{' '}
                  <a href='tel:8882543521'>888-254-3521</a> and asking for
                  Pamela Casper or by filling out the form below. Adam Langino
                  is licensed to practice law in North Carolina and Florida. He
                  regularly handles catastrophic injury and wrongful death
                  claims in both states, and co-counsels claims nationally. He
                  has the knowledge, experience, and courage to stand up to
                  insurance companies and large corporations on behalf of his
                  clients, providing a secure future for you and your family.
                  Please know, sending Langino Law PLLC a message is not
                  retaining him or his law firm as your attorney and does not
                  make you a client of the firm.
                </p>
              </div>
            </div>

            <ContactFormCmp />
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
